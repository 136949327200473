import React, { FC } from 'react';

interface IProps {
  style: {
    fill?: string;
    height?: string;
    width?: string;
  };
}

const StationHybridBusTramWaltti: FC<IProps> = ({
  style,
}: React.SVGProps<SVGSVGElement>) => {
  const { fill, width, height } = style;
  const colors = fill.split(',');
  const color = colors[0];
  const secondaryColor = colors.length > 1 ? colors[1] : undefined;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1004 19H1.90027C0.850596 19 0 18.1494 0 17.1004V1.90027C0 0.8506 0.850596 3.8147e-06 1.90027 3.8147e-06H17.1004C18.1501 3.8147e-06 19.0007 0.8506 19.0007 1.90027V17.1004C19 18.1494 18.1494 19 17.1004 19Z"
        fill={color}
      />
      <path
        d="M14.3093 1.90026H4.69136C4.16049 1.90026 3.72949 2.33059 3.72949 2.86213V14.4038C3.72949 14.9347 4.15982 15.3657 4.69136 15.3657H14.3093C14.8402 15.3657 15.2712 14.9354 15.2712 14.4038V2.86213C15.2705 2.33059 14.8402 1.90026 14.3093 1.90026ZM7.36246 2.75957H11.6376V3.48349H7.36246V2.75957ZM5.42733 13.6417C5.02047 13.6417 4.69136 13.3119 4.69136 12.9057C4.69136 12.4989 5.02114 12.1698 5.42733 12.1698C5.8342 12.1698 6.16331 12.4995 6.16331 12.9057C6.16398 13.3119 5.8342 13.6417 5.42733 13.6417ZM13.5727 13.6417C13.1658 13.6417 12.8367 13.3119 12.8367 12.9057C12.8367 12.4989 13.1665 12.1698 13.5727 12.1698C13.9795 12.1698 14.3087 12.4995 14.3087 12.9057C14.3093 13.3119 13.9795 13.6417 13.5727 13.6417ZM14.3093 9.79895C14.3093 9.97322 14.1927 10.1254 14.0245 10.1703C13.3542 10.3506 11.5759 10.7682 9.50001 10.7682C7.42412 10.7682 5.64585 10.3506 4.97556 10.1703C4.80732 10.1254 4.69069 9.97322 4.69069 9.79895V4.58678C4.69069 4.3743 4.86295 4.20204 5.07543 4.20204H13.9239C14.1364 4.20204 14.3087 4.3743 14.3087 4.58678V9.79895H14.3093Z"
        fill="white"
      />
      <path
        d="M6.16397 15.3489H4.69135V17.1004H6.16397V15.3489Z"
        fill="white"
      />
      <path
        d="M14.3093 15.3489H12.8367V17.1004H14.3093V15.3489Z"
        fill="white"
      />
      <mask
        id="mask0_557:1398"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path d="M32 0L0 32H32V0Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_557:1398)">
        <path
          d="M30.0998 32H14.9002C13.8506 32 13 31.1494 13 30.1004V14.9003C13 13.8506 13.8506 13 14.9002 13H30.0998C31.1494 13 32 13.8506 32 14.9003V30.1004C31.9993 31.1494 31.1488 32 30.0998 32Z"
          fill={secondaryColor || 'black'}
        />
        <path
          d="M26.979 27.5466V19.4093C26.979 18.7169 26.4173 16.891 25.725 16.891H23.5553L24.1874 15.5753H25.2504V14.9009H19.7502V15.5753H20.8247L21.4567 16.891H19.275C18.5826 16.891 18.0209 18.7169 18.0209 19.4093V27.5466C18.0209 28.239 18.5819 28.8001 19.2743 28.8007L18.3373 30.1011H19.7087L20.6457 28.8007H24.3543L25.2913 30.1011H26.6627L25.7256 28.8007C26.418 28.8001 26.979 28.239 26.979 27.5466ZM21.5646 15.5753H23.4481L22.816 16.891H22.1967L21.5646 15.5753ZM20.509 17.6914H24.491V18.3657H20.509V17.6914ZM18.9164 26.6364C18.9164 26.2576 19.2234 25.9507 19.6021 25.9507C19.9808 25.9507 20.2878 26.2576 20.2878 26.6364C20.2878 27.0151 19.9808 27.3221 19.6021 27.3221C19.2234 27.3221 18.9164 27.0151 18.9164 26.6364ZM25.3972 27.3221C25.0185 27.3221 24.7115 27.0151 24.7115 26.6364C24.7115 26.2576 25.0185 25.9507 25.3972 25.9507C25.7759 25.9507 26.0829 26.2576 26.0829 26.6364C26.0829 27.0151 25.7759 27.3221 25.3972 27.3221ZM26.0829 24.6275C26.0829 24.7897 25.9964 24.9311 25.8711 24.9734C25.3717 25.1409 24.0466 25.277 22.4996 25.277C20.9527 25.277 19.6276 25.1409 19.1282 24.9734C19.0029 24.9311 18.9164 24.7897 18.9164 24.6275V19.3932C18.9164 19.1955 19.0444 19.0346 19.2033 19.0346H25.7967C25.9549 19.0346 26.0835 19.1948 26.0835 19.3932V24.6275H26.0829Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default StationHybridBusTramWaltti;
